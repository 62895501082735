import React from "react"
import apiRequest from "../../hooks/HttpRequest"
import { Link } from "gatsby"
import axios from "axios"
import { navigate } from "gatsby"
import { isLoggedIn } from "../../services/auth"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default class ForgotUserName extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            FName: "",
            LName: "",
            PrimaryPhone: "",
            PrimaryEmail: "",
            MemberID: ""
        }

        this.sendForgotUserNameEmail = this.sendForgotUserNameEmail.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    handleOnChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value, errorMessage: "" })
        }
    }


    sendForgotUserNameEmail = (event) => {
        event.preventDefault()
        const jsonPayload = {
            "FName": this.state.FName,
            "LName": this.state.LName,
            "PrimaryEmail": this.state.PrimaryEmail,
            "PrimaryPhone": this.state.PrimaryPhone,
            "MemberID": this.state.MemberID
        }

        axios
            .post(
                apiRequest.baseURL + "/Members/ForgotUserName/",
                jsonPayload,
                apiRequest.jsonHeader
            )
            .then((response) => {
                // console.log(response.data.Status)
                // console.log(response.data.Status === "Error")
                if (response.data.Status === "Success") {
                    toast.success("Request Submitted. KyTS Admininistator shall contact you! ");
                    this.setState({
                        FName: "",
                        LName: "",
                        PrimaryPhone: "",
                        PrimaryEmail: "",
                        MemberID: ""
                    });
                }
                if (response.data.Status === "Error") {
                    toast.error("Invalid Details! Check Inputs!!");

                }
            })
            .catch((e) => {
                // console.log(e)
            })
    }



    render() {
        if (isLoggedIn()) {
            navigate(`/members/profile`)
        }
        return (
            <>
                <ToastContainer />
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h2>Forgot Username</h2>
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>Forgot Username</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="recover-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-form-action">
                                    <div className="form-heading text-center">
                                        <h3 className="form-title">Forgot Username?</h3>
                                        <p className="reset-desc">
                                            Please furnish below details. &nbsp; &nbsp;
                                            <Link to="/contact">contact us.</Link>
                                        </p>
                                    </div>
                                    <form method="post" onSubmit={this.sendForgotUserNameEmail}>
                                        <div className={!this.state.toShowPasswordForm ? "row" : "d-none"}>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        autoComplete="off"
                                                        name="FName"
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        onChange={this.handleOnChange}
                                                        value={this.state.FName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        autoComplete="off"
                                                        type="text"
                                                        name="LName"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        value={this.state.LName}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Email Address"
                                                        required
                                                        type="email"
                                                        name="PrimaryEmail"
                                                        className="form-control"
                                                        value={this.state.PrimaryEmail}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Phone Number"
                                                        required
                                                        type="tel"
                                                        name="PrimaryPhone"
                                                        className="form-control"
                                                        value={this.state.PrimaryPhone}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        autoComplete="off"
                                                        placeholder="Membership No"
                                                        type="number"
                                                        name="MemberID"
                                                        className="form-control"
                                                        value={this.state.MemberID}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="default-btn" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
