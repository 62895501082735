import React from "react"
import Layout from "../components/layout"
import ForgotUserPage from "../components/members/forgotUsername"

const ForgotUserName = () => {
    return (
        <Layout>
            <ForgotUserPage />
        </Layout>
    )
}

export default ForgotUserName